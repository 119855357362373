<template>
  <div>
    <div class="d-flex">
      <div class="mt-10">
        <b>Unique field combination</b>
      </div>
    </div>
    <div>
      <div
        class="artboard"
        v-if="
          templateData &&
          templateData.all_duplicate_rules &&
          templateData.all_duplicate_rules.length
        "
      >
        <el-scrollbar wrap-style="max-height: 60vh;">
          <div
            v-for="(rule, i) in templateData.all_duplicate_rules"
            :key="i + '_main'"
            class="active-board"
          >
            <span v-for="(field, j) in rule" :key="j + field.selected_field">
              <span v-if="j !== 0"> + </span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  field.selected_field +
                  ' ' +
                  field.operator +
                  ' ' +
                  (field.value_field ? field.value_field + ' ' : '') +
                  (field.value_field ? field.end_value_field + ' ' : '')
                "
                placement="top-start"
              >
                <el-tag class="m-1" :disable-transitions="false">{{
                  getFieldLable(field.selected_field)
                }}</el-tag>
              </el-tooltip>
            </span>
            <div class="float-right" style="margin-top: 6px">
              <el-link
                icon="el-icon-edit"
                plain
                class="add-rule"
                size="mini"
                :underline="false"
                type="primary"
                style="margin-right: 3px"
                :disabled="i == active_index"
                @click="editRuleCombo(i)"
              ></el-link>
              <el-link
                icon="el-icon-delete"
                plain
                class="add-rule"
                size="mini"
                :underline="false"
                type="danger"
                @click="deleteRuleCombo(i)"
              ></el-link>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div
        v-if="
          templateData &&
          templateData.all_duplicate_rules &&
          templateData.all_duplicate_rules.length
        "
        class="mt-1 d-flex"
      >
        <p>Note: These fields in combination will reject any duplicate data</p>
        <div class="float-right">
          <el-select
            v-model="templateData.duplicate_query_type"
            placeholder="Query type"
            size="mini"
          >
            <el-option
              v-for="op in [
                { name: 'All', value: '$and' },
                { name: 'Any one', value: '$or' },
              ]"
              :key="op.name + '_query_type'"
              :label="op.name"
              :value="op.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-else class="empty-artboard">
        <el-empty
          :image-size="50"
          description="No field combination added. please add fields from below form."
        ></el-empty>
      </div>
      <hr />
      <div v-if="active_rules && active_rules.length" class="active-board">
        <span
          v-for="(field, i) in active_rules"
          :key="i + field.selected_field + 'temp'"
        >
          <span v-if="i !== 0"> + </span>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              field.selected_field +
              ' ' +
              field.operator +
              ' ' +
              (field.value_field ? field.value_field + ' ' : '') +
              (field.value_field ? field.end_value_field + ' ' : '')
            "
            placement="top-start"
          >
            <el-tag
              :closable="active_rules.length > 1"
              class="m-1"
              @close="handleClose(i)"
              :disable-transitions="false"
              >{{ getFieldLable(field.selected_field) }}</el-tag
            >
          </el-tooltip>
        </span>
        <div class="float-right">
          <el-link
            icon="el-icon-delete"
            plain
            class="add-rule"
            size="mini"
            :underline="false"
            type="danger"
            style="margin-right: 5px; margin-top: 10px"
            @click="deleteDuplicateRuleCombo"
          ></el-link>
          <el-link
            icon="el-icon-plus"
            plain
            class="add-rule"
            size="mini"
            :underline="false"
            type="primary"
            :disabled="!active_rules.length"
            @click="addDuplicateRuleCombo"
            style="margin-top: 10px"
          ></el-link>
        </div>
      </div>
      <el-row class="mt-1 popUp-content" :gutter="30">
        <el-col :span="20">
          <b>Form</b>
          <div class="d-flex">
            <el-select
              v-model="new_rule.selected_field"
              size="mini"
              placeholder="Select field"
              @change="checkCurrentFieldData"
            >
              <el-option
                v-for="op in fields"
                :value="op.key"
                :label="op.label"
                :key="op.key + '_field'"
                :disabled="
                  getAllRules.findIndex((e) => e.selected_field == op.key) != -1
                "
              >
              </el-option>
            </el-select>
            <el-select
              v-model="new_rule.combination_type"
              size="mini"
              placeholder="Select field"
              :disabled="!isAllowedField"
              v-if="new_rule.selected_field"
              class="ml-1"
            >
              <el-option value="ALL" label="Without condition"> </el-option>
              <el-option value="CONDITION" label="With condition"> </el-option>
            </el-select>
          </div>
          <div
            class="d-flex mt-1"
            v-if="new_rule.combination_type == 'CONDITION' && isAllowedField"
          >
            <el-select
              v-model="new_rule.operator"
              size="mini"
              placeholder="Select operator"
            >
              <el-option
                v-for="(op, i) in getOperations"
                :key="i"
                :value="op.value"
                :label="op.title"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="new_rule.value"
              size="mini"
              placeholder="Select options"
              class="ml-1"
              v-if="
                new_rule.operator &&
                (new_rule.inputType == 'MULTI_SELECT' ||
                  new_rule.inputType == 'SELECT')
              "
              multiple
            >
              <el-option
                v-for="op in getValueFieldOptions"
                :value="op"
                :label="op"
                :key="op + 'start_field'"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="new_rule.value_field"
              size="mini"
              placeholder="Select field"
              class="ml-1"
              v-if="
                new_rule.operator &&
                new_rule.operator !== 'dateonly' &&
                new_rule.inputType !== 'MULTI_SELECT' &&
                new_rule.inputType !== 'SELECT'
              "
              @change="setInputType"
            >
              <el-option
                v-for="op in getValueFields"
                :value="op.key"
                :label="op.label"
                :key="op.key + 'start_field'"
                :disabled="new_rule.end_value_field == op.key"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="new_rule.end_value_field"
              size="mini"
              placeholder="Select end"
              class="ml-1"
              v-if="
                new_rule.operator &&
                new_rule.operator == 'notbetween' &&
                !isStartRangeField
              "
            >
              <el-option
                v-for="op in getValueFields"
                :value="op.key"
                :label="op.label"
                :key="op.key + 'end_field'"
                :disabled="new_rule.value_field == op.key"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <el-link
            icon="el-icon-plus"
            plain
            class="add-rule"
            size="mini"
            :underline="false"
            type="primary"
            @click="addDuplicateRule"
            :disabled="!new_rule.selected_field"
            >Add</el-link
          >
        </el-col>
      </el-row>
      <el-row class="mt-1 mb-1">
        <b>Error message</b>
        <el-input v-model="templateData.duplicate_message"></el-input>
        <span
          >Note: use $entity_name to get current entity name in error
          message</span
        >
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["fields", "templateData", "from_data_table"],
  components: {},
  computed: {
    ...mapGetters("companyTemplates", ["getTemplateDataTempVariable"]),
    isAllowedField() {
      if (this.from_data_table) {
        return false;
      }
      if (this.new_rule?.selected_field) {
        let field = this.fields.find(
          (e) => e.key == this.new_rule.selected_field
        );
        if (
          field?.inputType &&
          this.allowedFieldTypes.indexOf(field.inputType) != -1
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    getValueFields() {
      if (this.new_rule?.selected_field) {
        let field = this.fields.find(
          (e) => e.key == this.new_rule.selected_field
        );
        if (field?.inputType) {
          return this.fields.filter(
            (e) =>
              e.inputType == field.inputType ||
              (e.inputType == "FORMULA" && e.result_type == field.inputType)
          );
        }
        return [];
      }
      return [];
    },
    getAllRules() {
      if (this.active_rules && this.active_rules.length) {
        return this.active_rules || [];
      }
      return [];
    },
    getValueFieldOptions() {
      let options = [];
      if (this.new_rule.selected_field) {
        let field = this.fields.find(
          (e) => e.key == this.new_rule.selected_field
        );
        if (
          field &&
          (field.inputType == "MULTI_SELECT" || field.inputType == "SELECT")
        ) {
          options = field.options || [];
        }
        if (field?.input_type == "ENTITY_VARIABLE") {
          let selectedField = (
            this.getTemplateDataTempVariable?.[
              field.global_variable_entity_field_template_id
            ]?.sections?.[0]?.fields || []
          ).find(
            (e) => e.key == field.global_variable_entity_select_type_field_key
          );
          options = selectedField.options || [];
        }
      }
      return options;
    },
    getOperations() {
      let operators = this.allowedOperators;
      if (this.new_rule.selected_field) {
        let field = this.fields.find(
          (e) => e.key == this.new_rule.selected_field
        );
        if (
          field &&
          (field.inputType == "MULTI_SELECT" || field.inputType == "SELECT")
        ) {
          return [
            {
              title: "In",
              value: "in",
            },
            {
              title: "Not In",
              value: "nin",
            },
          ];
        }
        if (field.inputType == "DATE_TIME") {
          operators.push({
            title: "Compare date only",
            value: "dateonly",
          });
        }
        if (
          field &&
          (field.inputType == "DATE_TIME_RANGE" ||
            field.inputType == "TIME_RANGE" ||
            field.inputType == "DATE_RANGE")
        ) {
          return [
            {
              title: "Not between",
              value: "notbetween",
            },
          ];
        }
        return operators;
      }
      return operators;
    },
    isStartRangeField() {
      if (this.new_rule?.value_field) {
        let field = this.fields.find(
          (e) => e.key == this.new_rule.selected_field
        );
        return field &&
          (field.inputType == "DATE_TIME_RANGE" ||
            field.inputType == "TIME_RANGE" ||
            field.inputType == "DATE_RANGE")
          ? true
          : false;
      }
      return false;
    },
  },
  data() {
    return {
      all_duplicate_rules: [],
      new_rule: {
        selected_field: "",
        combination_type: "ALL",
        operator: "",
        value_field: "",
        end_value_field: "",
        inputType: "",
        value_inputType: "",
      },
      allowedFieldTypes: [
        "DATE",
        "NUMBER",
        "TIME",
        "DATE_TIME_RANGE",
        "DATE_RANGE",
        "LIST",
        "TIME_RANGE",
        "DATE_TIME",
        "SELECT",
        "MULTI_SELECT",
      ],
      allowedOperators: [
        {
          title: "Not between",
          value: "notbetween",
        },
        {
          title: "Greater Than (>)",
          value: ">",
        },
        {
          title: "Greater Than or Equal(>=)",
          value: ">=",
        },
        {
          title: "Less Than (<)",
          value: "<",
        },
        {
          title: "Less Than or Equal(<=)",
          value: "<=",
        },
      ],
      active_index: -1,
      active_rules: [],
    };
  },
  mounted() {
    if (this.templateData && !this.templateData.all_duplicate_rules) {
      this.$set(this.templateData, "all_duplicate_rules", []);
    }
  },
  methods: {
    deleteRuleCombo(i) {
      this.templateData.all_duplicate_rules.splice(i, 1);
    },
    editRuleCombo(i) {
      this.active_index = i;
      this.active_rules = this.templateData.all_duplicate_rules[i];
    },
    setInputType(value) {
      if (value) {
        let field = this.fields.find(
          (field) => field.key == this.new_rule.value_field
        );
        if (field?.inputType) {
          this.new_rule.value_inputType = field.inputType;
        }
      }
    },
    checkCurrentFieldData(value) {
      if (value) {
        let field = this.fields.find(
          (field) => field.key == this.new_rule.selected_field
        );
        if (field?.inputType) {
          this.new_rule.inputType = field.inputType;
          if (
            field.inputType == "SELECT" ||
            field.inputType == "LIST" ||
            field.inputType == "MULTI_SELECT" ||
            field.inputType == "WEEKDAYS"
          ) {
            this.new_rule.operator = "in";
          }
        }
      } else {
        this.new_rule.inputType = "";
      }
    },
    getPrefix(selected_field) {
      if (selected_field && this.fields) {
        let field = this.fields.find((e) => e.key == selected_field);
        if (
          field?.inputType == "DATE" ||
          (field.inputType == "FORMULA" && field.result_type == "DATE")
        ) {
          return "on";
        } else if (
          field?.inputType == "TIME" ||
          (field.inputType == "FORMULA" && field.result_type == "TIME")
        ) {
          return "at";
        }
        return "";
      }
      return "";
    },
    getFieldLable(key) {
      if (this.fields && this.fields.length) {
        let field = this.fields.find((e) => e.key == key);
        return field?.label || "";
      }
      return "";
    },
    handleClose(i) {
      this.active_rules.splice(i, 1);
    },
    deleteDuplicateRuleCombo() {
      if (this.active_index != -1) {
        this.templateData.all_duplicate_rules.splice(this.active_index, 1);
      }
      this.active_index = -1;
      this.active_rules = [];
    },
    addDuplicateRuleCombo() {
      if (this.active_index == -1) {
        this.templateData.all_duplicate_rules.push(this.active_rules);
      } else {
        this.templateData.all_duplicate_rules[this.active_index] =
          this.active_rules;
      }
      this.active_index = -1;
      this.active_rules = [];
    },
    addDuplicateRule() {
      if (this.templateData && !this.templateData.all_duplicate_rules) {
        this.templateData.all_duplicate_rules = [];
      }
      this.active_rules.push(JSON.parse(JSON.stringify(this.new_rule)));
      this.new_rule.selected_field = "";
      this.new_rule.combination_type = "ALL";
      this.new_rule.operator = "";
      this.new_rule.end_value_field = "";
      this.new_rule.value_field = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-alert {
  text-align: center !important;
  img {
    width: 100px;
    margin-bottom: 10px;
  }
}
.artboard {
  background: rgba(245, 245, 245, 0.8);
  border: 1px dashed #d2d2d2;
  border-radius: 0px;
  padding: 0.5em;
  // min-height: 60px;
}
.empty-artboard {
  background: rgba(245, 245, 245, 0.8);
  border: 1px dashed #d2d2d2;
  border-radius: 0px;
  min-height: 60px;
  text-align: center !important;
  color: #777777;
}
.active-board {
  border: 1px solid #d2d2d2;
  padding: 5px;
}
</style>
